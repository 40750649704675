import { Grid, GridProps, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import {
  Accenture,
  BCG,
  Disney,
  Golin,
  IPG,
  Omnicon,
  SaatchiSaatchi,
  Uber,
  Walmart,
} from '@/components/atoms/Icon';
import WPP from '@/components/atoms/Icon/WPP';

export interface CompaniesUsingWaldoProps extends GridProps {}

const Logos = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.disabled,
  maxWidth: theme.spacing(63.375),
}));

const CompaniesUsingWaldo: FunctionComponent<CompaniesUsingWaldoProps> = (
  props,
) => (
  <Logos container alignItems="center" justifyContent="center" {...props}>
    <Grid item>
      <SaatchiSaatchi />
    </Grid>
    <Grid item>
      <Disney />
    </Grid>
    <Grid item>
      <IPG />
    </Grid>
    <Grid item>
      <Walmart />
    </Grid>
    <Grid item>
      <Uber />
    </Grid>
    <Grid item>
      <Golin />
    </Grid>
    <Grid item>
      <Omnicon />
    </Grid>
    <Grid item>
      <BCG />
    </Grid>
    <Grid item>
      <WPP />
    </Grid>
    <Grid item>
      <Accenture />
    </Grid>
  </Logos>
);

export default CompaniesUsingWaldo;
